import {
  BeakerIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  DocumentIcon,
  FolderIcon,
  InformationCircleIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useActiveOrg } from '../../../propelauth';
import { trpc } from '../../../trpc';
import { classNames } from '../../../utils/classNames';
import { LinkArrow } from '../../LinkArrow/LinkArrow';
import { Loader } from '../../Loader/Loader';

export default function WelcomeScreen() {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const { pathname } = useLocation();
  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: projectId!,
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );
  const { data: project, isLoading } = projectQuery;

  if (isLoading || !project) {
    return (
      <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
        <Loader />
      </div>
    );
  }

  const actions = [
    {
      title: t('projects.welcomeScreen.actions.documents.title', { count: project.documentsCount }),
      href: pathname + '/documents',
      text: t('projects.welcomeScreen.actions.documents.subtitle'),
      icon: DocumentIcon,
      iconBackground: 'bg-green-500',
    },
    {
      title: t('projects.welcomeScreen.actions.testCases.title', { count: project.testCasesCount }),
      href: pathname + '/test-cases',
      text: t('projects.welcomeScreen.actions.testCases.subtitle'),
      icon: BeakerIcon,
      iconBackground: 'bg-purple-500',
    },
    {
      title: t('projects.welcomeScreen.actions.recordings.title', { count: project.recordingsCount }),
      href: pathname + '/recordings',
      text: t('projects.welcomeScreen.actions.recordings.subtitle'),
      icon: CodeBracketIcon,
      iconBackground: 'bg-sky-500',
    },
    {
      title: t('projects.welcomeScreen.actions.settings.title'),
      href: `/app/projects/${project.id}/settings`,
      text: t('projects.welcomeScreen.actions.settings.subtitle'),
      icon: Cog6ToothIcon,
      iconBackground: 'bg-yellow-500',
    },
  ];

  return (
    <div className="mx-auto max-w-4xl">
      <div className="grid grid-cols-1 gap-6">
        <div className="grid grid-cols-1 gap-3">
          <div className="flex items-center gap-2">
            <FolderIcon className="text-slate-300 h-6 w-6 shrink-0" />
            <p className="text-lg font-semibold leading-6 text-white">{project.name}</p>
          </div>
        </div>

        {!project?.integration && (
          <div className="rounded-md bg-blue-900 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-50" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-50">{t('projects.welcomeScreen.noIntegration.title')}</h3>
                <div className="mt-2 text-sm text-blue-300">
                  <p>{t('projects.welcomeScreen.noIntegration.subtitle')}</p>
                </div>
                <div className="mt-4 text-sm text-blue-300">
                  <LinkArrow
                    text={t('projects.welcomeScreen.noIntegration.CTA')}
                    to={`/app/projects/${project.id}/settings`}
                    colour="white"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="divide-y divide-slate-100 overflow-hidden rounded-lg bg-slate-1000 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'group relative bg-slate-800 p-6 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    // action.iconForeground,
                    'inline-flex rounded-lg p-3 ring-4 ring-slate-700 text-white'
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-white">
                  <Link to={action.href} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </Link>
                </h3>
                <p className="mt-2 text-sm text-gray-400">{action.text}</p>
              </div>
              <span
                className="pointer-events-none absolute right-6 top-6 text-gray-400 group-hover:text-gray-200"
                aria-hidden="true"
              >
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
