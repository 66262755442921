import { ChevronRightIcon, FolderIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useActiveOrg } from '../../../propelauth';
import { trpc } from '../../../trpc';
import { classNames } from '../../../utils/classNames';

export default function ProjectsWelcomeScreen() {
  const { t } = useTranslation();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const getProjectsQuery = trpc.projects.getProjects.useQuery({ orgId }, { enabled: !!orgId });
  const { data: projects, isLoading } = getProjectsQuery;

  const items =
    projects?.map((project) => ({
      name: project.name,
      href: `/app/projects/${project.id}/settings`,
      iconColor: 'bg-slate-500',
      icon: FolderIcon,
    })) || [];

  return (
    <div className="mx-auto max-w-lg">
      <h2 className="text-base font-semibold leading-6 text-white">{t('settings.projects.title')}</h2>
      <p className="mt-1 text-sm text-gray-400">{t('settings.projects.subtitle')}</p>

      {(items.length > 0 || isLoading) && (
        <ul role="list" className="mt-6 divide-y divide-slate-800 border-b border-t border-slate-800">
          {isLoading && <p className="text-sm text-gray-400 py-6">{t('common.loading')}</p>}

          {!isLoading &&
            items.map((item: any, itemIdx: number) => (
              <li key={itemIdx}>
                <div className="group relative flex items-center space-x-3 py-4">
                  <div className="flex-shrink-0">
                    <span
                      className={classNames(
                        item.iconColor,
                        'inline-flex h-10 w-10 items-center justify-center rounded-lg'
                      )}
                    >
                      <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="text-sm font-medium text-white">
                      <Link to={item.href}>
                        <span className="absolute inset-0" aria-hidden="true" />
                        {item.name}
                      </Link>
                    </div>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray-300 group-hover:text-gray-100" aria-hidden="true" />
                  </div>
                </div>
              </li>
            ))}
        </ul>
      )}

      {!isLoading && items.length === 0 && (
        <div className="mt-6">
          <Link to="/app/projects/create" className="text-sm font-medium text-indigo-400 hover:text-indigo-300">
            {t('settings.projects.createFirstProject')}
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      )}
    </div>
  );
}
