import { DocumentIcon, MagnifyingGlassIcon, PlusIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { DOC_COUNT_PER_PAGE } from '../../../constants';
import { useModals } from '../../../providers/modals';
import { useNotifications } from '../../../providers/notifications';
import { useActiveOrg } from '../../propelauth';
import { trpc } from '../../trpc';
import { Button } from '../Button';
import { DocTypeLabel } from '../DocTypeLabel/DocTypeLabel';
import { Dropdown, type DropdownAction } from '../Dropdown';
import { Pagination } from '../Pagination/Pagination';
import { Table } from '../Table/Table';

export const DocumentsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const { openModal } = useModals();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const [jiraSyncStatus, setJiraSyncStatus] = useState<string>();
  const [needJiraSync, setNeedJiraSync] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [scoreFilterValue, setScoreFilterValue] = useState<string>();
  const [testCasesFilter, setTestCasesFilter] = useState<string>();
  const [automatedTestsFilter, setAutomatedCasesFilter] = useState<string>();
  const [needFetchDocumentsFromDB, setNeedFetchDocumentsFromDB] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get('page') || 1;
  const { sendNotification } = useNotifications();

  // 0- count number of documents in db
  const getDocumentsCountQuery = trpc.documents.getDocumentsCount.useQuery(
    {
      projectId: projectId!,
      orgId,
      searchKeyword,
      scoreFilterValue,
      testCasesFilter,
      automatedTestsFilter,
    },
    {
      enabled: !!orgId && !!projectId,
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const { data: countDocuments } = getDocumentsCountQuery;

  const documentDeleteMutation = trpc.documents.deleteDocument.useMutation({
    onSuccess: () => {
      console.log('Document deleted successfully');
      documentsQuery.refetch();
      sendNotification({
        id: 'document-delete-success',
        text: t('documents.deleteSuccess'),
        type: 'success',
        autoDismiss: true,
      });
    },
    onError: (error) => {
      console.error("Couldn't delete document", error);
    },
  });

  // 1- load existing documents from DB
  const documentsQuery = trpc.documents.getDocuments.useQuery(
    {
      projectId: projectId ?? '',
      orgId,
      currentPage: Number(currentPage),
      searchKeyword,
      scoreFilterValue,
      testCasesFilter,
      automatedTestsFilter,
    },
    {
      enabled: !!orgId && !!projectId,
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const { data: documents, isLoading: isLoadingDocuments } = documentsQuery;

  // 2- sync with Jira, after user has clicked on sync button
  trpc.documents.fetchJiraDocsAndUpdate.useQuery(
    {
      projectId: projectId ?? '',
      orgId,
    },
    {
      // The query will not execute until the needJiraSync is true
      enabled: !!orgId && !!projectId && !!documents && needJiraSync,
      onSuccess: (data: any) => {
        documentsQuery.refetch();
        console.log('Jira synced done successfully');
        setNeedJiraSync(false);
        setJiraSyncStatus(data);
      },
    }
  );

  // 3- notify user that the sync is done
  useEffect(() => {
    if (jiraSyncStatus === 'success') {
      sendNotification({
        id: 'jira-sync-success',
        text: t('documents.jiraSyncSuccess'),
        subText: t('documents.jiraSyncSuccessSubtitle'),
        type: 'success',
        autoDismiss: true,
      });
    }
  }, [jiraSyncStatus]);

  // 4- fetch project infomation
  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project } = projectQuery;

  // 5- apply search and filters
  const displayStatus = (status: string) => {
    const trimmedStatus = status.trim().toLowerCase();
    if (trimmedStatus === 'todo') {
      return (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
          {status}
        </span>
      );
    } else if (trimmedStatus === 'inprogress') {
      return (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
          {status}
        </span>
      );
    } else if (trimmedStatus === 'done') {
      return (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
          {status}
        </span>
      );
    } else {
      return <span className="text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-cyan-900 text-cyan-500">{status}</span>;
    }
  };

  const handleSyncWithJiraClick = () => {
    setNeedJiraSync(true);
  };

  const handleRowClick = (docId: string) => {
    navigate(`/app/projects/${projectId}/documents/${docId}`);
  };

  const handlePaginationClick = (currentPage: number) => {
    setSearchParams({ page: currentPage.toString() });
  };

  const handleClickScoreFilter = (scoreFilter: string) => {
    setScoreFilterValue(scoreFilter);
  };

  const handleClickTestCasesFilter = (testCasesFilter: string) => {
    setTestCasesFilter(testCasesFilter);
  };

  const handleClickAutomatedTestsFilter = (automatedTestsFilter: string) => {
    setAutomatedCasesFilter(automatedTestsFilter);
  };

  useEffect(() => {
    const shouldRefetch = [searchKeyword, scoreFilterValue, testCasesFilter, automatedTestsFilter].some((value) =>
      Boolean(value)
    );

    if (shouldRefetch) {
      getDocumentsCountQuery.refetch();
      documentsQuery.refetch();
    }
  }, [searchKeyword, scoreFilterValue, testCasesFilter, automatedTestsFilter]);

  useEffect(() => {
    if (inputValue === searchKeyword) {
      return;
    }

    // Handle the actual setting of the search keyword after a debounce period
    const timeoutId = setTimeout(() => {
      setSearchKeyword(inputValue);
    }, 500);

    // Clear the timeout if inputValue changes or the component unmounts
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSelectItem = (e: any) => {
    const newSelectedItems = selectedItems.includes(e.target.value)
      ? selectedItems.filter((item) => item !== e.target.value)
      : [...selectedItems, e.target.value];
    setSelectedItems(newSelectedItems);
  };

  const handleSelectAllClick = (e: any) => {
    e.stopPropagation();
    const newSelectedItems = selectedItems.length === documents?.length ? [] : documents?.map((d: any) => d.id);
    setSelectedItems(newSelectedItems ?? []);
  };

  const dropdownActions: DropdownAction[] = [
    {
      text: 'Delete',
      action: () => {
        openModal({
          id: 'delete-documents',
          type: 'danger',
          text: 'Delete documents',
          subText: 'Are you sure you want to delete the selected documents?',
          onConfirm: () => {
            selectedItems.forEach((id) => {
              documentDeleteMutation.mutate({ id, orgId: orgId! });
            });
            setSelectedItems([]);
          },
        });
      },
      icon: TrashIcon,
      danger: true,
    },
  ];

  return (
    <div>
      <div className="mx-auto">
        <div className="sm:flex sm:justify-between">
          <div className="sm:flex sm:items-center space-x-4">
            {/** Search */}
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="search"
                  className="block w-full rounded-md border-0 bg-slate-900 py-2 pl-10 pr-10 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder={t('documents.searchAndFilters.search')}
                  value={inputValue}
                  onChange={handleInputChange}
                />

                {inputValue && (
                  <div
                    aria-role="button"
                    onClick={() => setInputValue('')}
                    className="group cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <XCircleIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-100" aria-hidden="true" />
                  </div>
                )}
              </div>
            </div>

            {/** Filters */}
            <div className="sm:flex space-x-4">
              <select
                id="scoreFilter"
                name="scoreFilter"
                defaultValue=""
                className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                onChange={(e) => handleClickScoreFilter(e.target.value)}
              >
                <option value="" disabled>
                  {t('documents.searchAndFilters.score.default')}
                </option>
                <option key="no_score" value="no_score">
                  {t('documents.searchAndFilters.score.noScore')}
                </option>
                <option key="lower_than_20" value="bad">
                  {t('documents.searchAndFilters.score.lower20')}
                </option>
                <option key="between_20_and_80" value="medium">
                  {t('documents.searchAndFilters.score.between20And80')}
                </option>
                <option key="greater_than_80" value="good">
                  {t('documents.searchAndFilters.score.greater80')}
                </option>
              </select>
              <select
                id="withTestCasesFilter"
                name="withTestCasesFilter"
                defaultValue=""
                className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                onChange={(e) => handleClickTestCasesFilter(e.target.value)}
              >
                <option value="" disabled>
                  {t('documents.searchAndFilters.tests.default')}
                </option>
                <option key="no_test" value="no_test">
                  {t('documents.searchAndFilters.tests.without')}
                </option>
                <option key="with_tests" value="with_test">
                  {t('documents.searchAndFilters.tests.with')}
                </option>
              </select>
              <select
                id="withAutomatedTestsFilter"
                name="withAutomatedTestsFilter"
                defaultValue=""
                className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                onChange={(e) => handleClickAutomatedTestsFilter(e.target.value)}
              >
                <option value="" disabled>
                  {t('documents.searchAndFilters.automatedTests.default')}
                </option>
                <option key="no_test" value="no_test">
                  {t('documents.searchAndFilters.automatedTests.without')}
                </option>
                <option key="with_tests" value="with_test">
                  {t('documents.searchAndFilters.automatedTests.with')}
                </option>
              </select>
            </div>
          </div>

          <div className="flex items-center gap-4">
            {selectedItems.length > 0 && <Dropdown text={t('common.actions')} actions={dropdownActions} />}

            {project?.integration === 'Jira' && (
              <div className="">
                <Button text={t('documents.syncCTA')} onClick={handleSyncWithJiraClick} style="primary" />
              </div>
            )}
            <div className="">
              <Button
                text={t('documents.createCTA')}
                onClick={() => navigate(`/app/projects/${projectId}/documents/create`)}
                style="secondary"
              />
            </div>
          </div>
        </div>

        {!isLoadingDocuments && documents?.length === 0 ? (
          <div className="grid grid-cols-1 mt-20 justify-items-center items-center">
            {searchKeyword?.length ? (
              <div className="mt-6 flex flex-col items-center text-center">
                <DocumentIcon className="h-14 w-14 text-slate-500" />
                <h3 className="mt-2 text-sm font-semibold text-white">No documents found</h3>
                <p className="mt-1 text-sm text-gray-400">
                  No documents found for the keyword: <code>{searchKeyword}</code>
                </p>
              </div>
            ) : (
              <div className="mt-6 flex flex-col items-center text-center">
                <DocumentIcon className="h-14 w-14 text-slate-500" />
                <h3 className="mt-2 text-sm font-semibold text-white">No documents</h3>
                {project?.integration ? (
                  <>
                    <p className="mt-1 text-sm text-gray-400">There are no documents in this project for now.</p>
                    <p className="text-sm text-gray-400">
                      You can sync with Jira to create documents from your Jira issues.
                    </p>
                    <div className="mt-6">
                      <Button
                        text={t('documents.syncCTA')}
                        onClick={handleSyncWithJiraClick}
                        loading={needJiraSync}
                        style="primary"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="mt-1 text-sm text-gray-400">Get started by creating a new document.</p>
                    <div className="mt-6">
                      <a
                        href={`/app/projects/${project?.id}/documents/create`}
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        New Document
                      </a>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <Table
                    handleSelectItem={handleSelectItem}
                    handleSelectAllClick={handleSelectAllClick}
                    selectedItems={selectedItems}
                    isLoading={isLoadingDocuments}
                    items={documents ?? []}
                    headers={[
                      t('documents.list.headers.document'),
                      t('documents.list.headers.type'),
                      t('documents.list.headers.status'),
                      t('documents.list.headers.score'),
                      t('documents.list.headers.testCases'),
                      t('documents.list.headers.automatedTests'),
                    ]}
                    cells={[
                      {
                        render: (item) => (
                          <div className="flex flex-col gap-1 text-slate-300 group-hover:text-indigo-300 transition-all duration-1">
                            <span className="text-xs font-medium text-slate-400 group-hover:text-indigo-300 transition-all duration-1">
                              {item.key}
                            </span>
                            <span className="text-sm font-medium">{item.title}</span>
                          </div>
                        ),
                        wrap: true,
                      },
                      {
                        render: (item) => <DocTypeLabel type={item.type} />,
                      },
                      {
                        render: (item) => displayStatus(item.status),
                      },
                      {
                        render: (item) => (
                          <span className={`text-sm font-medmium text-${item.score ? 'slate-300' : 'slate-500'}`}>
                            {item.score ? `${item.score}/100` : '–'}
                          </span>
                        ),
                      },
                      {
                        render: (item) => (
                          <span
                            className={`text-sm font-medium text-${item.testCasesCount && item.testCasesCount != null ? 'slate-300' : 'slate-500'}`}
                          >
                            {item.testCasesCount && item.testCasesCount != null
                              ? `${item.testCasesCount} test cases`
                              : '–'}
                          </span>
                        ),
                      },
                      {
                        render: (item) => (
                          <span
                            className={`text-sm font-medium text-${item.testCasesCount && item.automatedTestsCount != null ? 'slate-300' : 'slate-500'}`}
                          >
                            {item.testCasesCount && item.automatedTestsCount != null
                              ? `${item.automatedTestsCount} automated tests`
                              : '–'}
                          </span>
                        ),
                      },
                    ]}
                    onRowClick={handleRowClick}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <Pagination
                goToPage={handlePaginationClick}
                currentPage={Number(currentPage)}
                pageTotal={countDocuments ? Math.ceil(countDocuments / DOC_COUNT_PER_PAGE) : 0}
                totalItem={countDocuments || 0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
