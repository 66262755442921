import { useState } from 'react';
import ContentLoader from 'react-content-loader';

type Cell = {
  render: (item: any) => React.ReactNode;
  wrap?: boolean;
};

type TableProps = {
  headers: (string | React.ReactNode)[];
  cells: Cell[];
  items: any[];
  onRowClick: (id: string) => void;
  selectedItems?: string[];
  handleSelectItem?: (e: any) => void;
  handleSelectAllClick?: (e: any) => void;
  isLoading?: boolean;
};

export const Table = ({
  headers,
  cells,
  items,
  onRowClick,
  selectedItems,
  handleSelectItem,
  handleSelectAllClick,
  isLoading,
}: TableProps) => {
  const [tableRef, setTableRef] = useState<HTMLTableElement | null>(null);
  const tableHeaderElement = tableRef?.querySelector('thead');

  return (
    <table className="border-collapse min-w-full" ref={(ref) => setTableRef(ref)}>
      <thead className="group border-b border-transparent uppercase text-left text-xs font-semibold text-slate-600 hover:text-slate-300 hover:border-slate-800 transition-all duration-1">
        <tr>
          {selectedItems && (
            <th scope="col" className="min-w-[16px] w-[16px] py-4 px-4 pr-0 rounded-l-xl">
              <input
                type="checkbox"
                checked={!isLoading && items.every((c) => selectedItems.includes(c.id))}
                className="bg-slate-900 rounded border-slate-800 text-indigo-500 focus:ring-indigo-500 group-hover:bg-slate-700 group-hover:border-slate-600 transition-all duration-1"
                onChange={handleSelectAllClick}
              />
            </th>
          )}
          {headers.map((header, index) => (
            <th scope="col" className="py-4 px-4" key={index}>
              {header}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <tr key={`table-loader-${index}`}>
                <td colSpan={headers.length + 1} key={index}>
                  <ContentLoader
                    speed={2}
                    width={tableRef?.clientWidth}
                    height={70}
                    viewBox={`0 0 ${tableRef?.clientWidth} 70`}
                    backgroundColor="#2c3849"
                    foregroundColor="#546174"
                  >
                    {[...headers, selectedItems ? 'checkbox' : ''].map((header, index) => {
                      const headerElement = tableHeaderElement?.querySelector(`th:nth-child(${index + 1})`);
                      const elementPosition = headerElement?.offsetLeft;
                      const elementWidth = headerElement?.offsetWidth;

                      return (
                        <rect
                          key={`table-loader-${index}-${header}`}
                          x={elementPosition + 15}
                          y="28"
                          rx="5"
                          ry="5"
                          width={Math.max(elementWidth - 150, 17)}
                          height="17"
                        />
                      );
                    })}
                  </ContentLoader>
                </td>
              </tr>
            ))
          : items.map((item) => (
              <tr
                key={`${item.id}-row`}
                className="cursor-pointer group hover:bg-slate-900 transition-all duration-1"
                onClick={() => onRowClick(item.id)}
              >
                {selectedItems && (
                  <td className="rounded-l-xl py-4 px-4 pr-0">
                    <input
                      type="checkbox"
                      value={item.id}
                      checked={selectedItems.includes(item.id)}
                      className="bg-slate-700 rounded border-slate-600 text-indigo-500 focus:ring-indigo-500"
                      onChange={handleSelectItem}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                )}

                {cells.map(({ render, wrap = false }, index) => (
                  <td
                    key={`${item.id}-cell-${index}`}
                    className={`${wrap ? '' : 'whitespace-nowrap'} py-4 px-4 ${index === 0 && !selectedItems ? 'rounded-l-xl' : ''} ${index === cells.length - 1 ? 'rounded-r-xl' : ''}`}
                  >
                    {render(item)}
                  </td>
                ))}
              </tr>
            ))}
      </tbody>
    </table>
  );
};
