import { BeakerIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TEST_CASES_PER_PAGE } from '../../../constants';
import { useActiveOrg } from '../../propelauth';
import { trpc } from '../../trpc';
import { Pagination } from '../Pagination/Pagination';
import { Table } from '../Table/Table';

export function TestCasesList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [automatedTestsFilter, setAutomatedCasesFilter] = useState<string>();
  const [frameworkFilter, setFrameworkFilter] = useState<string>();
  const [needFetchTestCasesFromDB, setNeedFetchTestCasesFromDB] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const currentPage = searchParams.get('page') || 1;

  // 0- count number of test cases in db
  const getTestCasesCountQuery = trpc.testCases.getTestCasesByProjectCount.useQuery(
    {
      projectId: projectId!,
      orgId,
      searchKeyword,
      automatedTestsFilter,
      frameworkFilter,
    },
    {
      enabled: !!orgId && !!projectId,
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const { data: countTests } = getTestCasesCountQuery;

  // 1- load existing test cases from DB
  const testCasesQuery = trpc.testCases.getTestCasesByProject.useQuery(
    {
      projectId: projectId!,
      orgId,
      searchKeyword,
      automatedTestsFilter,
      frameworkFilter,
      currentPage: Number(currentPage),
    },
    {
      enabled: !!orgId && !!projectId && needFetchTestCasesFromDB,
      onSuccess: (data: any) => {
        setNeedFetchTestCasesFromDB(false);
      },
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const { data: testCases, isLoading: isLoadingTestCases } = testCasesQuery;
  console.log('testCases by project from the DB:', testCases);

  const handlePaginationClick = (currentPage: number) => {
    setSearchParams({ page: currentPage.toString() });
    setNeedFetchTestCasesFromDB(true);
  };

  const handleClickAutomatedTestsFilter = (automatedTestsFilter: string) => {
    setAutomatedCasesFilter(automatedTestsFilter);
    setNeedFetchTestCasesFromDB(true);
  };

  const handleClickFrameworkFilter = (frameworkFilter: string) => {
    setFrameworkFilter(frameworkFilter);
    setNeedFetchTestCasesFromDB(true);
  };

  const handleSearchChange = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
    setNeedFetchTestCasesFromDB(true);
  };

  const handleRowClick = (testCaseId: string) => {
    navigate(`/app/projects/${projectId}/test-cases/${testCaseId}`);
  };

  return (
    <div className="">
      <div className="mx-auto">
        {/** No results */}
        {!isLoadingTestCases &&
          (!searchKeyword || searchKeyword.length === 0) &&
          (!automatedTestsFilter || automatedTestsFilter.length === 0) &&
          (!frameworkFilter || frameworkFilter.length === 0) &&
          testCases?.length === 0 && (
            <div className="mt-6 flex flex-col items-center text-center">
              <BeakerIcon className="h-14 w-14 text-slate-500" />
              <h3 className="mt-2 text-sm font-semibold text-white">{t('testCases.emptyState.title')}</h3>
              <p className="mt-1 text-sm text-gray-400">{t('testCases.emptyState.subtitle')}</p>
            </div>
          )}

        {/** List */}
        <div>
          <div className="mx-auto">
            <div className="sm:flex sm:justify-between">
              <div className="sm:flex sm:items-center space-x-4">
                {/** Search */}
                <div className="flex rounded-md shadow-sm">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border-0 bg-slate-900 py-2 pl-10 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder={t('testCases.searchAndFilters.search')}
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
                </div>
                {/** Filters */}
                <div className="sm:flex space-x-4">
                  <select
                    id="withAutomatedTestsFilter"
                    name="withAutomatedTestsFilter"
                    defaultValue=""
                    className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => handleClickAutomatedTestsFilter(e.target.value)}
                  >
                    <option value="" disabled>
                      {t('testCases.searchAndFilters.automatedTests.default')}
                    </option>
                    <option key="no_test" value="no_test">
                      {t('testCases.searchAndFilters.automatedTests.without')}
                    </option>
                    <option key="with_tests" value="with_test">
                      {t('testCases.searchAndFilters.automatedTests.with')}
                    </option>
                  </select>
                  <select
                    id="frameworkFilter"
                    name="frameworkFilter"
                    defaultValue=""
                    className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => handleClickFrameworkFilter(e.target.value)}
                  >
                    <option value="" disabled>
                      {t('testCases.searchAndFilters.framework.name')}
                    </option>
                    <option key="cypress" value="cypress">
                      {t('testCases.searchAndFilters.framework.cypress')}
                    </option>
                    <option key="junit" value="junit">
                      {t('testCases.searchAndFilters.framework.junit')}
                    </option>
                    <option key="pytest" value="pytest">
                      {t('testCases.searchAndFilters.framework.pytest')}
                    </option>
                    <option key="rspec" value="rspec">
                      {t('testCases.searchAndFilters.framework.rspec')}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <Table
                    isLoading={isLoadingTestCases}
                    items={testCases}
                    headers={[
                      t('testCases.list.headers.document'),
                      t('testCases.list.headers.title'),
                      t('testCases.list.headers.steps'),
                      t('testCases.list.headers.automatedTests'),
                      t('testCases.list.headers.framework'),
                      t('testCases.list.headers.htmlFlows'),
                    ]}
                    cells={[
                      {
                        render: (item) => (
                          <div className="flex flex-col gap-0.5 text-slate-300 group-hover:text-indigo-300 transition-all duration-1">
                            <span className="font-semibold text-xs text-slate-500 group-hover:text-indigo-300 transition-all duration-1">
                              {item.documentKey}
                            </span>
                            <span className="text-sm font-medium">{item.documentTitle}</span>
                          </div>
                        ),
                        wrap: true,
                      },
                      {
                        render: (item) => (
                          <span className="text-sm font-medium text-slate-300 group-hover:text-indigo-300 transition-all duration-1">
                            {item.title}
                          </span>
                        ),
                        wrap: true,
                      },
                      {
                        render: (item) => (
                          <span className={`text-sm text-${item.steps ? 'slate-400' : 'slate-600'}`}>
                            {item.steps ? JSON.parse(item.steps).length : t('common.none')}
                          </span>
                        ),
                      },
                      {
                        render: (item) => (
                          <span className={`text-sm text-${item.automatedTestsCount > 0 ? 'slate-400' : 'slate-600'}`}>
                            {item.automatedTestsCount > 0 ? 'Yes' : '–'}
                          </span>
                        ),
                      },
                      {
                        render: (item) => (
                          <span className={`text-sm text-${item.automatedTestsCount > 0 ? 'slate-400' : 'slate-600'}`}>
                            {item.automatedTestsCount > 0 ? `${item.framework}` : '–'}
                          </span>
                        ),
                      },
                      {
                        render: (item) => (
                          <span className={`text-sm text-${item.flowId ? 'slate-400' : 'slate-600'}`}>
                            {item.flowId ? 'Yes' : '–'}
                          </span>
                        ),
                      },
                    ]}
                    onRowClick={handleRowClick}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <Pagination
                goToPage={handlePaginationClick}
                currentPage={Number(currentPage)}
                pageTotal={countTests ? Math.ceil(countTests / TEST_CASES_PER_PAGE) : 0}
                totalItem={countTests || 0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
