import {
  ArrowLeftEndOnRectangleIcon,
  BeakerIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  DocumentIcon,
  EyeIcon,
  FolderIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom';

import { useActiveOrg, useLogoutFunction } from '../../propelauth';
import { trpc } from '../../trpc';
import { LinkArrow } from '../LinkArrow/LinkArrow';
import { Typo } from '../Typo';

export const ProjectNavigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logout = useLogoutFunction();
  const { projectId } = useParams<{ projectId: string }>();
  const orgId = useActiveOrg()?.orgId ?? '';

  const getProjectsQuery = trpc.projects.getProject.useQuery(
    {
      projectId: projectId ?? '',
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );
  const project = getProjectsQuery.data;

  const items = [
    {
      title: t('menu.overview'),
      href: `/app/projects/${projectId}`,
      icon: EyeIcon,
      current: useMatch({ path: '/app/projects/:projectId', end: true }),
    },
    {
      title: t('menu.documents'),
      href: `/app/projects/${projectId}/documents`,
      icon: DocumentIcon,
      current: useMatch({ path: '/app/projects/:projectId/documents', end: false }),
    },
    {
      title: t('menu.testCases'),
      href: `/app/projects/${projectId}/test-cases`,
      icon: BeakerIcon,
      current: useMatch({ path: '/app/projects/:projectId/test-cases', end: false }),
    },
    {
      title: t('menu.recordings'),
      href: `/app/projects/${projectId}/recordings`,
      icon: CodeBracketIcon,
      current: useMatch({ path: '/app/projects/:projectId/recordings', end: false }),
    },
    {
      title: t('menu.projectSettings'),
      href: `/app/projects/${projectId}/settings`,
      icon: FolderIcon,
      current: useMatch({ path: '/app/projects/:projectId/settings', end: false }),
    },
  ];

  return (
    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <LinkArrow text="Projects" to="/app/projects" arrowSide="left" colour="slate" />
        </li>

        <li className="flex flex-col gap-2">
          <Typo size="lg">{project?.name || 'Project'}</Typo>

          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li className="-mx-6">
              {items.map((item) => (
                <div key={item.href} className={`flex flex-col w-full`}>
                  <Link to={item.href}>
                    <div
                      className={`group flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 hover:text-white cursor-pointer ${item.current ? 'bg-gray-900 text-slate-100' : 'text-slate-300'}`}
                    >
                      <item.icon
                        className={`h-6 w-6 shrink-0 ${item.current ? 'text-slate-100' : 'text-slate-400'} group-hover:text-white`}
                        aria-hidden="true"
                      />
                      <span className="sr-only">{item.title}</span>
                      <span aria-hidden="true">{item.title}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </li>
          </ul>
        </li>

        <li className="-mx-6 mt-auto">
          <Link
            to="/app/settings/account"
            className={`group flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 hover:text-white cursor-pointer ${useMatch({ path: '/app/settings/account', end: true }) ? 'bg-gray-900 text-slate-100' : 'text-slate-300'}`}
          >
            <Cog6ToothIcon
              className={`h-6 w-6 shrink-0 ${useMatch({ path: '/app/settings/account', end: true }) ? 'text-slate-100' : 'text-slate-400'} group-hover:text-white`}
              aria-hidden="true"
            />
            <span className="sr-only">{t('menu.personalPreferences')}</span>
            <span aria-hidden="true">{t('menu.personalPreferences')}</span>
          </Link>
          <Link
            to="/app/settings/account"
            onClick={() => logout(true)}
            className="group flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-slate-300 hover:text-white cursor-pointer"
          >
            <ArrowLeftEndOnRectangleIcon
              className="h-6 w-6 shrink-0 text-slate-400 group-hover:text-white"
              aria-hidden="true"
            />
            <span className="sr-only">{t('menu.logout')}</span>
            <span aria-hidden="true">{t('menu.logout')}</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
