import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useBillingGuard } from '../../../providers/billingGuard';
import { saveOrgSelectionToLocalStorage, useActiveOrg, useAuthInfo } from '../../propelauth';
import { trpc } from '../../trpc';
import OrganisationSelect from '../OrganisationSelect/OrganisationSelect';

type BreadcrumbElement = {
  title: string;
  href: string;
  icon?: any;
};

export default function Breadcrumb() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const auth = useAuthInfo();
  // @ts-ignore
  const { orgHelper } = auth;
  const orgs = orgHelper?.getOrgs?.() ?? [];
  const orgsCount = orgs.length;
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const { setOrgId } = useBillingGuard();

  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: params.projectId || '',
      orgId,
    },
    {
      enabled: !!orgId && !!params.projectId,
    }
  );
  const { data: project } = projectQuery;

  const documentQuery = trpc.documents.getDocument.useQuery(
    {
      documentId: params.documentId || '',
      projectId: params.projectId || '',
    },
    {
      enabled: !!params.documentId && !!params.projectId,
    }
  );
  const { data: documentRes } = documentQuery;
  const { document } = documentRes || {};

  const testCaseQuery = trpc.testCases.getTestCase.useQuery(
    {
      testCaseId: params.testCaseId || '',
      projectId: params.projectId || '',
    },
    {
      enabled: !!params.testCaseId && !!params.projectId,
    }
  );
  const { data: testCaseRes } = testCaseQuery;
  const { testCase } = testCaseRes || {};

  const getProjectsQuery = trpc.projects.getOrgProjects.useQuery({ orgId }, { enabled: !!orgId });

  const buildBreadcrumb = (pathname: string) => {
    const elems: BreadcrumbElement[] = [];
    const splitPath = pathname.split('/').filter(Boolean);

    // Settings
    if (pathname.includes('app/settings')) {
      splitPath.forEach((path, i) => {
        // Settings
        if (path === 'settings') {
          elems.push({ title: t('breadcrumbs.settings'), href: '/app/settings/organisation' });

          if (splitPath[i + 1] === 'organisation') {
            elems.push({ title: t('breadcrumbs.organisation'), href: '/app/settings/organisation' });
          }

          if (splitPath[i + 2] === 'team') {
            elems.push({ title: t('breadcrumbs.team'), href: '/app/settings/organisation/team' });
          }

          if (splitPath[i + 2] === 'billing') {
            elems.push({ title: t('breadcrumbs.billing'), href: '/app/settings/organisation/billing' });
          }

          if (splitPath[i + 2] === 'usage') {
            elems.push({ title: t('breadcrumbs.usage'), href: '/app/settings/organisation/usage' });
          }
        }

        if (path === 'account') {
          elems.push({ title: t('breadcrumbs.account'), href: pathname });
        }
      });
    }

    if (pathname.includes('app/projects')) {
      elems.push({ title: t('breadcrumbs.projects'), href: '/app/projects' });

      if (splitPath[1] === 'projects' && splitPath[2]) {
        if (splitPath[2] === 'create') {
          elems.push({ title: t('breadcrumbs.create'), href: pathname });
        } else {
          elems.push({
            title: project?.name || t('breadcrumbs.project'),
            href: `/${splitPath[0]}/${splitPath[1]}/${splitPath[2]}`,
          });
        }
      }

      if (splitPath[3]) {
        const readableTitle = splitPath[3].charAt(0).toUpperCase() + splitPath[3].slice(1).replace(/-/g, ' ');

        elems.push({
          title: readableTitle,
          href: `/${splitPath[0]}/${splitPath[1]}/${splitPath[2]}/${splitPath[3]}`,
        });
      }

      if (splitPath[3] === 'documents' && splitPath[4]) {
        if (splitPath[4] === 'create') {
          elems.push({
            title: t('breadcrumbs.create'),
            href: `/${splitPath[0]}/${splitPath[1]}/${splitPath[2]}/${splitPath[3]}/${splitPath[4]}`,
          });
        } else if (document) {
          const readableTitle = document.key!;

          elems.push({
            title: readableTitle,
            href: `/${splitPath[0]}/${splitPath[1]}/${splitPath[2]}/${splitPath[3]}/${splitPath[4]}`,
          });
        }
      }

      if (splitPath[3] === 'test-cases' && splitPath[4]) {
        if (testCase) {
          const readableTitle = testCase.title;

          elems.push({
            title: readableTitle,
            href: `/${splitPath[0]}/${splitPath[1]}/${splitPath[2]}/${splitPath[3]}/${splitPath[4]}`,
          });
        }
      }
    }

    return elems;
  };

  const pages = buildBreadcrumb(pathname);

  const handleOrgChange = (org: any) => {
    const newOrgId = org.id;
    saveOrgSelectionToLocalStorage(newOrgId);
    setOrgId(newOrgId);
    getProjectsQuery.refetch();
    navigate('/app');
  };

  return (
    <nav className="flex border-gray-800" aria-label="Breadcrumb">
      <ol role="list" className={`mx-auto flex w-full max-w-screen-xl space-x-1 ${orgsCount === 1 ? 'pl-4' : ''}`}>
        {orgsCount > 1 && (
          <li key="org-dropdown" className="flex mr-2">
            <div className="flex items-center bg-white/5">
              <OrganisationSelect onChange={handleOrgChange} />
            </div>
            <div className="arrow-right" />
          </li>
        )}
        {pages.map((page, i) => (
          <li key={page.title} className="flex">
            <div className="flex items-center">
              {i !== 0 ? (
                <>
                  <svg
                    className="h-full w-6 flex-shrink-0 text-gray-800"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link to={page.href} className="ml-4 text-sm font-medium text-gray-300 hover:text-gray-200">
                    {page.title}
                  </Link>
                </>
              ) : (
                <Link
                  to={page.href}
                  className="flex items-center text-sm font-medium text-gray-300 hover:text-gray-200"
                >
                  {page.icon && <page.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />}
                  {page.title}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
