import { ChevronRightIcon, FolderIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useBillingGuard } from '../../../../providers/billingGuard';
import { useActiveOrg } from '../../../propelauth';
import { trpc } from '../../../trpc';
import { classNames } from '../../../utils/classNames';
import { Button } from '../../Button';
export const ProjectsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const activeOrg = useActiveOrg();
  const { hasReachedProjectsCountLimit } = useBillingGuard();
  const orgId = activeOrg?.orgId ?? '';
  const getProjectsQuery = trpc.projects.getProjects.useQuery({ orgId }, { enabled: !!orgId });
  const { data: projects, isLoading } = getProjectsQuery;

  if (!projects) {
    return null;
  }

  const projectItems =
    projects?.map((project) => ({
      name: project.name,
      href: `/app/projects/${project.id}`,
      iconColor: 'bg-slate-500',
      icon: FolderIcon,
    })) || [];

  return (
    <div>
      {projects.length === 0 && (
        <div className="mt-6 flex flex-col items-center text-center">
          <FolderIcon className="h-14 w-14 text-slate-500" />
          <h3 className="mt-2 text-sm font-semibold text-white">{t('projects.emptyList.title')}</h3>
          <p className="mt-1 text-sm text-gray-400">{t('projects.emptyList.subtitle')}</p>
          <div className="mt-6">
            <Link
              to="/app/projects/create"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {t('projects.createCTA')}
            </Link>
          </div>
        </div>
      )}

      {(projects.length > 0 || isLoading) && (
        <div className="mx-auto max-w-lg flex flex-col items-start gap-6">
          <div>
            <h2 className="text-base font-semibold leading-6 text-white">{t('projects.list.title')}</h2>
            <p className="mt-1 text-sm text-gray-400">{t('projects.list.subtitle')}</p>
          </div>

          <ul role="list" className="divide-y divide-slate-800 border-b border-t border-slate-800 w-full">
            {isLoading && <p className="text-sm text-gray-400 py-6">{t('common.loading')}</p>}

            {!isLoading &&
              projectItems.map((item: any, itemIdx: number) => (
                <li key={itemIdx}>
                  <div className="group relative flex items-center space-x-3 py-4">
                    <div className="flex-shrink-0">
                      <span
                        className={classNames(
                          item.iconColor,
                          'inline-flex h-10 w-10 items-center justify-center rounded-lg'
                        )}
                      >
                        <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div className="text-sm font-medium text-white">
                        <Link to={item.href}>
                          <span className="absolute inset-0" aria-hidden="true" />
                          {item.name}
                        </Link>
                      </div>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-300 group-hover:text-gray-100"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </li>
              ))}
          </ul>

          <p
            data-tooltip-id="project-limitation-tooltip"
            data-tooltip-html={hasReachedProjectsCountLimit ? t('projects.reachedLimitTooltip') : ''}
          >
            <Button
              text={t('projects.createCTA')}
              onClick={() => navigate('/app/projects/create')}
              disabled={hasReachedProjectsCountLimit}
              style="primary"
            />
          </p>
        </div>
      )}

      <Tooltip
        id="project-limitation-tooltip"
        place="bottom-start"
        style={{ backgroundColor: 'rgb(100, 116, 139)', color: '#fff', maxWidth: '360px' }}
        opacity={1}
        clickable
        className="clickable-tooltip"
      />
    </div>
  );
};
