import { DocumentsList } from './DocumentsList/DocumentsList';
import { Layout } from './Layout';

export function Documents() {
  return (
    <Layout title={`Projects / Project / User stories`}>
      <DocumentsList />
    </Layout>
  );
}
